import * as React from 'react';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import classNames from 'classnames';
import TransButton from '../../_util/transButton';
import Tooltip from '../../tooltip';
import { getNode, toList } from './util';
export default function CopyBtn(props) {
    const { prefixCls, copied, locale = {}, onCopy, iconOnly, tooltips, icon } = props;
    const tooltipNodes = toList(tooltips);
    const iconNodes = toList(icon);
    const { copied: copiedText, copy: copyText } = locale;
    const copyTitle = copied
        ? getNode(tooltipNodes[1], copiedText)
        : getNode(tooltipNodes[0], copyText);
    const systemStr = copied ? copiedText : copyText;
    const ariaLabel = typeof copyTitle === 'string' ? copyTitle : systemStr;
    return (React.createElement(Tooltip, { key: "copy", title: copyTitle },
        React.createElement(TransButton, { className: classNames(`${prefixCls}-copy`, {
                [`${prefixCls}-copy-success`]: copied,
                [`${prefixCls}-copy-icon-only`]: iconOnly,
            }), onClick: onCopy, "aria-label": ariaLabel }, copied
            ? getNode(iconNodes[1], React.createElement(CheckOutlined, null), true)
            : getNode(iconNodes[0], React.createElement(CopyOutlined, null), true))));
}
