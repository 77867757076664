import * as React from 'react';
import classNames from 'classnames';
import useClosable from '../_util/hooks/useClosable';
import { ConfigContext } from '../config-provider';
const DrawerPanel = (props) => {
    var _a, _b;
    const { prefixCls, title, footer, extra, closeIcon, closable, onClose, headerStyle, bodyStyle, footerStyle, children, classNames: drawerClassNames, styles: drawerStyles, } = props;
    const { drawer: drawerContext } = React.useContext(ConfigContext);
    const customCloseIconRender = React.useCallback((icon) => (React.createElement("button", { type: "button", onClick: onClose, "aria-label": "Close", className: `${prefixCls}-close` }, icon)), [onClose]);
    const mergedContextCloseIcon = React.useMemo(() => {
        if (typeof (drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.closable) === 'object' && drawerContext.closable.closeIcon) {
            return drawerContext.closable.closeIcon;
        }
        return drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.closeIcon;
    }, [drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.closable, drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.closeIcon]);
    const [mergedClosable, mergedCloseIcon] = useClosable({
        closable: closable !== null && closable !== void 0 ? closable : drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.closable,
        closeIcon: typeof closeIcon !== 'undefined' ? closeIcon : mergedContextCloseIcon,
        customCloseIconRender,
        defaultClosable: true,
    });
    const headerNode = React.useMemo(() => {
        var _a, _b;
        if (!title && !mergedClosable) {
            return null;
        }
        return (React.createElement("div", { style: Object.assign(Object.assign(Object.assign({}, (_a = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.styles) === null || _a === void 0 ? void 0 : _a.header), headerStyle), drawerStyles === null || drawerStyles === void 0 ? void 0 : drawerStyles.header), className: classNames(`${prefixCls}-header`, {
                [`${prefixCls}-header-close-only`]: mergedClosable && !title && !extra,
            }, (_b = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.classNames) === null || _b === void 0 ? void 0 : _b.header, drawerClassNames === null || drawerClassNames === void 0 ? void 0 : drawerClassNames.header) },
            React.createElement("div", { className: `${prefixCls}-header-title` },
                mergedCloseIcon,
                title && React.createElement("div", { className: `${prefixCls}-title` }, title)),
            extra && React.createElement("div", { className: `${prefixCls}-extra` }, extra)));
    }, [mergedClosable, mergedCloseIcon, extra, headerStyle, prefixCls, title]);
    const footerNode = React.useMemo(() => {
        var _a, _b;
        if (!footer) {
            return null;
        }
        const footerClassName = `${prefixCls}-footer`;
        return (React.createElement("div", { className: classNames(footerClassName, (_a = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.classNames) === null || _a === void 0 ? void 0 : _a.footer, drawerClassNames === null || drawerClassNames === void 0 ? void 0 : drawerClassNames.footer), style: Object.assign(Object.assign(Object.assign({}, (_b = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.styles) === null || _b === void 0 ? void 0 : _b.footer), footerStyle), drawerStyles === null || drawerStyles === void 0 ? void 0 : drawerStyles.footer) }, footer));
    }, [footer, footerStyle, prefixCls]);
    return (React.createElement(React.Fragment, null,
        headerNode,
        React.createElement("div", { className: classNames(`${prefixCls}-body`, drawerClassNames === null || drawerClassNames === void 0 ? void 0 : drawerClassNames.body, (_a = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.classNames) === null || _a === void 0 ? void 0 : _a.body), style: Object.assign(Object.assign(Object.assign({}, (_b = drawerContext === null || drawerContext === void 0 ? void 0 : drawerContext.styles) === null || _b === void 0 ? void 0 : _b.body), bodyStyle), drawerStyles === null || drawerStyles === void 0 ? void 0 : drawerStyles.body) }, children),
        footerNode));
};
export default DrawerPanel;
