var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { forwardRef } from 'react';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import classNames from 'classnames';
import RcMenu from 'rc-menu';
import { useEvent } from 'rc-util';
import omit from 'rc-util/lib/omit';
import initCollapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import { devUseWarning } from '../_util/warning';
import { ConfigContext } from '../config-provider';
import useCSSVarCls from '../config-provider/hooks/useCSSVarCls';
import useItems from './hooks/useItems';
import MenuContext from './MenuContext';
import OverrideContext from './OverrideContext';
import useStyle from './style';
function isEmptyIcon(icon) {
    return icon === null || icon === false;
}
const InternalMenu = forwardRef((props, ref) => {
    var _a;
    const override = React.useContext(OverrideContext);
    const overrideObj = override || {};
    const { getPrefixCls, getPopupContainer, direction, menu } = React.useContext(ConfigContext);
    const rootPrefixCls = getPrefixCls();
    const { prefixCls: customizePrefixCls, className, style, theme = 'light', expandIcon, _internalDisableMenuItemTitleTooltip, inlineCollapsed, siderCollapsed, items, children, rootClassName, mode, selectable, onClick, overflowedIndicatorPopupClassName } = props, restProps = __rest(props, ["prefixCls", "className", "style", "theme", "expandIcon", "_internalDisableMenuItemTitleTooltip", "inlineCollapsed", "siderCollapsed", "items", "children", "rootClassName", "mode", "selectable", "onClick", "overflowedIndicatorPopupClassName"]);
    const passedProps = omit(restProps, ['collapsedWidth']);
    // ========================= Items ===========================
    const mergedChildren = useItems(items) || children;
    // ======================== Warning ==========================
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Menu');
        warning(!('inlineCollapsed' in props && mode !== 'inline'), 'usage', '`inlineCollapsed` should only be used when `mode` is inline.');
        warning(!(props.siderCollapsed !== undefined && 'inlineCollapsed' in props), 'usage', '`inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.');
        warning.deprecated('items' in props && !children, 'children', 'items');
    }
    (_a = overrideObj.validator) === null || _a === void 0 ? void 0 : _a.call(overrideObj, { mode });
    // ========================== Click ==========================
    // Tell dropdown that item clicked
    const onItemClick = useEvent((...args) => {
        var _a;
        onClick === null || onClick === void 0 ? void 0 : onClick(...args);
        (_a = overrideObj.onClick) === null || _a === void 0 ? void 0 : _a.call(overrideObj);
    });
    // ========================== Mode ===========================
    const mergedMode = overrideObj.mode || mode;
    // ======================= Selectable ========================
    const mergedSelectable = selectable !== null && selectable !== void 0 ? selectable : overrideObj.selectable;
    // ======================== Collapsed ========================
    // Inline Collapsed
    const mergedInlineCollapsed = React.useMemo(() => {
        if (siderCollapsed !== undefined) {
            return siderCollapsed;
        }
        return inlineCollapsed;
    }, [inlineCollapsed, siderCollapsed]);
    const defaultMotions = {
        horizontal: { motionName: `${rootPrefixCls}-slide-up` },
        inline: initCollapseMotion(rootPrefixCls),
        other: { motionName: `${rootPrefixCls}-zoom-big` },
    };
    const prefixCls = getPrefixCls('menu', customizePrefixCls || overrideObj.prefixCls);
    const rootCls = useCSSVarCls(prefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls, !override);
    const menuClassName = classNames(`${prefixCls}-${theme}`, menu === null || menu === void 0 ? void 0 : menu.className, className);
    // ====================== ExpandIcon ========================
    const mergedExpandIcon = React.useMemo(() => {
        var _a, _b;
        if (typeof expandIcon === 'function' || isEmptyIcon(expandIcon)) {
            return expandIcon || null;
        }
        if (typeof overrideObj.expandIcon === 'function' || isEmptyIcon(overrideObj.expandIcon)) {
            return overrideObj.expandIcon || null;
        }
        if (typeof (menu === null || menu === void 0 ? void 0 : menu.expandIcon) === 'function' || isEmptyIcon(menu === null || menu === void 0 ? void 0 : menu.expandIcon)) {
            return (menu === null || menu === void 0 ? void 0 : menu.expandIcon) || null;
        }
        const mergedIcon = (_a = expandIcon !== null && expandIcon !== void 0 ? expandIcon : overrideObj === null || overrideObj === void 0 ? void 0 : overrideObj.expandIcon) !== null && _a !== void 0 ? _a : menu === null || menu === void 0 ? void 0 : menu.expandIcon;
        return cloneElement(mergedIcon, {
            className: classNames(`${prefixCls}-submenu-expand-icon`, React.isValidElement(mergedIcon) ? (_b = mergedIcon.props) === null || _b === void 0 ? void 0 : _b.className : undefined),
        });
    }, [expandIcon, overrideObj === null || overrideObj === void 0 ? void 0 : overrideObj.expandIcon, menu === null || menu === void 0 ? void 0 : menu.expandIcon, prefixCls]);
    // ======================== Context ==========================
    const contextValue = React.useMemo(() => ({
        prefixCls,
        inlineCollapsed: mergedInlineCollapsed || false,
        direction,
        firstLevel: true,
        theme,
        mode: mergedMode,
        disableMenuItemTitleTooltip: _internalDisableMenuItemTitleTooltip,
    }), [prefixCls, mergedInlineCollapsed, direction, _internalDisableMenuItemTitleTooltip, theme]);
    // ========================= Render ==========================
    return wrapCSSVar(React.createElement(OverrideContext.Provider, { value: null },
        React.createElement(MenuContext.Provider, { value: contextValue },
            React.createElement(RcMenu, Object.assign({ getPopupContainer: getPopupContainer, overflowedIndicator: React.createElement(EllipsisOutlined, null), overflowedIndicatorPopupClassName: classNames(prefixCls, `${prefixCls}-${theme}`, overflowedIndicatorPopupClassName), mode: mergedMode, selectable: mergedSelectable, onClick: onItemClick }, passedProps, { inlineCollapsed: mergedInlineCollapsed, style: Object.assign(Object.assign({}, menu === null || menu === void 0 ? void 0 : menu.style), style), className: menuClassName, prefixCls: prefixCls, direction: direction, defaultMotions: defaultMotions, expandIcon: mergedExpandIcon, ref: ref, rootClassName: classNames(rootClassName, hashId, overrideObj.rootClassName, cssVarCls, rootCls) }), mergedChildren))));
});
export default InternalMenu;
