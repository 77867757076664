var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import pickAttrs from 'rc-util/lib/pickAttrs';
function useInnerClosable(closable, closeIcon, defaultClosable) {
    if (typeof closable === 'boolean') {
        return closable;
    }
    if (typeof closable === 'object') {
        return true;
    }
    if (closeIcon === undefined) {
        return !!defaultClosable;
    }
    return closeIcon !== false && closeIcon !== null;
}
function useClosable({ closable, closeIcon, customCloseIconRender, defaultCloseIcon = React.createElement(CloseOutlined, null), defaultClosable = false, }) {
    const mergedClosable = useInnerClosable(closable, closeIcon, defaultClosable);
    if (!mergedClosable) {
        return [false, null];
    }
    const _a = typeof closable === 'object'
        ? closable
        : {}, { closeIcon: closableIcon } = _a, restProps = __rest(_a, ["closeIcon"]);
    // Priority: closable.closeIcon > closeIcon > defaultCloseIcon
    const mergedCloseIcon = (() => {
        if (typeof closable === 'object' && closableIcon !== undefined) {
            return closableIcon;
        }
        return typeof closeIcon === 'boolean' || closeIcon === undefined || closeIcon === null
            ? defaultCloseIcon
            : closeIcon;
    })();
    const ariaProps = pickAttrs(restProps, true);
    const plainCloseIcon = customCloseIconRender
        ? customCloseIconRender(mergedCloseIcon)
        : mergedCloseIcon;
    const closeIconWithAria = React.isValidElement(plainCloseIcon) ? (React.cloneElement(plainCloseIcon, ariaProps)) : (React.createElement("span", Object.assign({}, ariaProps), plainCloseIcon));
    return [true, closeIconWithAria];
}
export default useClosable;
